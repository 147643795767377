import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import unitsImage from "../assets/muk.jpg"
import Loader from './Loader'
import "../css/Structure.css"

const Structure = () => {
    const title = ""
    const cls = 'header-other'

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : 
            <div>
                <Header cls={cls} vid={false} heading={'Department Structure'} title={title} img={unitsImage}/>
                <div className='Structure'>
                    <div className='inner pb-2' style={{background:'whitesmoke'}}>
                        <h6 className='pt-4 fw-bolder'>Organization of the Department</h6>
                        <h6 className='d-none'>Organization of the Department</h6>
                        <p>The Department is organized through Coordinators who assist the Head of Department in the day to day running of the affairs. <br />These include;</p>
                        <ol>
                            <li>Coordinator of Examinations</li>
                            <li>Coordinator of Graduate programmes</li>
                            <li>Coordinator of Undergraduate programmes (Bachelor Geographical Sciences)</li>
                            <li>Coordinator of Undergraduate Programmes (Bachelor of Science in Meteorology)</li>
                            <li>Secretary to the Departmental Academic Board</li>
                            <li>Secretary to the Departmental Appointments and Promotions Committee</li>
                            <li>Finance and Welfare Officer</li>
                            <li>Media Communications Officer</li>
                        </ol>
                    </div>
                </div>
                <Footer />
            </div>}
        </>
    )
}

export default Structure