import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import "../css/Header.css"
import video from "../assets/video.mp4"
import { useActivePage } from './ActivePageContext'

export const Nav = () => {
    const [scrolling, setScrolling] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const { activePage, setActivePage } = useActivePage();

    const handlePageChange = (page) => {
        setActivePage(page);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    const handleMenu = () => {
        if (isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }
    return(
        <div className={`nav-menu d-flex w-100 justify-content-between align-items-center ${scrolling ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
            <Link className='department'><img src='/images/Mak-Logo-Mak-100-Light.png' /><span>Department of Geography, Geo-informatics and Climatic Sciences</span></Link>
            <Link className='menu' onClick={handleMenu}><i class="fa-solid fa-bars"></i> Menu</Link>
            <nav className={isVisible ? 'd-block' :''}>
                <Link to={'/'} onClick={() => handlePageChange('home')} className={activePage === 'home' ? 'active' : ''}>Home</Link>
                <Link onClick={() => handlePageChange('about')} className={activePage === 'about' ? 'active' : ''}>
                    About Us
                    <div>
                        <Link to={'/welcome-message'}>Welcome Mesage</Link>
                        <Link to={'/structure'}>Department Structure</Link>
                    </div>
                </Link>
                
                <Link onClick={() => handlePageChange('people')} className={activePage === 'people' ? 'active' : ''}>
                    People
                    <div>
                        <Link to={'/people/former-leaders'}>Former Leaders</Link>
                        <Link to={'/people/teaching-staff'}>Teaching Staff</Link>
                        <Link to={'/people/non-teaching-staff'}>Non Teaching Staff</Link>
                        <Link to={'/people/visiting-scholars'}>Visiting Scholars</Link>
                        <Link to={'/people/graduate-students'}>Graduate Students</Link>
                    </div>
                </Link>
                <Link onClick={() => handlePageChange('projects')} className={activePage === 'projects' ? 'active' : ''}>
                    Research Themes & Projects
                    <div>
                        <Link to={'/projects/breed'}>BREED</Link>
                        <Link to={'/projects/peer'}>PEER</Link>
                        <Link to={'/projects/pelibigo'}>PEBILIGO</Link>
                        <Link to={'/projects/vogrow'}>VOGrow</Link>
                        <Link to={'/projects/agrifose2030'}>AGRIFOSE2030</Link>
                    </div>
                </Link>
                <Link onClick={() => handlePageChange('units')} className={activePage === 'units' ? 'active' : ''}>
                    Units
                    <div>
                        <Link to={'/units/meteorology-unit'}>Meteorology Unit</Link>
                        <Link to={'/units/climate-and-water-resources-center'}>Climate & Water Resources Center</Link>
                        <Link to={'/units/mountain-resources-center'}>Mountain Resources Center</Link>
                        <Link to={'/units/urban-action-lab'}>Urban Action Lab</Link>
                    </div>
                </Link>
                <Link onClick={() => handlePageChange('associations')} className={activePage === 'associations' ? 'active' : ''}>
                    Associations
                    <div>
                        <Link to={'/associations/uganda-geography-association'}>Uganda Geography Association</Link>
                    </div>
                </Link>
                <Link to={'/contact'} onClick={() => handlePageChange('contact')} className={activePage === 'contact' ? 'active' : ''}>Contact</Link>
            </nav>
        </div>
    )
}


const Header = ({vid = true, img, cls, heading, title}) => {
    const [scrolling, setScrolling] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    };

    return (
        <header className={`${cls}`}>
            {vid ? 
                <video
                    autoPlay
                    loop
                    muted
                    className="header-video"
                    src={video}
                />:
                <img className="header-video" src={img} />}
            <Nav />
            <div className={`${vid ? 'headerContent':'headerContentOther'}`}>
                <h1>{heading}</h1>
                <p>{title}</p>
            </div>
        </header>
    );
}

export default Header