import React, {useEffect, useState} from 'react'
import "../css/WelcomeMessage.css"
import DrMukwaya from "../assets/Dr_Mukwaya.jpg"
import contactImage from "../assets/NBGL-Lake-Mutanda-2.webp"
import Header from './Header'
import Footer from './Footer'
import { Nav } from './Header'
import Loader from './Loader'

const WelcomeMessage = () => {
    const heading = "Welcome Message From The Chair"
    const title = "Dr. Paul Isolo Mukwaya"
    const cls = 'header-other'


    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={heading} title={title} img={contactImage}/>
            <div className='WelcomeMesssage'>
                <div className='inner'>
                    <div className='d-block d-lg-flex align-items-center gap-4'>
                        <div>
                            <img src={DrMukwaya} />
                            <p>The Chair, <span className='fw-bold'>Dr. Paul Isolo Mukwaya</span></p>
                        </div>
                        <div>
                            <p>Hello and welcome to the Department of Geography, Geoinformatics and Climatic Sciences, one of the Departments within the School of Forestry, Environmental and Geographical Sciences, Makerere University.</p>
                            <p>We offer crosscutting academic programmes and research, combining the aspects of Human, Physical and Environmental Geography. Our research foci spans climate science, climate change and earth observation; Coupled human-environment systems, demography and development; disaster risk management and reduction and Urban and regional systems, transformations and sustainability. We are a highly networked Department working with several local, regional and international collaborators to address some of the contemporary issues of our time. We are open to more partnerships and collaborations.</p>
                        </div>
                    </div>
                    <h6>We are currently running the following graduate and undergraduate programmes;</h6>
                    <ol>
                        <li>PhD in Geography (by Research)</li>
                        <li>Master of Science in Climate Change and Sustainability</li>
                        <li>Masters of Geographical Sciences (2 years)</li>
                        <li>Master of Science in Disaster Risk Management (2years)</li>
                        <li>Master of Land use and Regional Development Planning (2 years)</li>
                        <li>Master of Science in Applied Meteorology (2 years)</li>
                        <li>Bachelor of Science in Meteorology (3 years)</li>
                        <li>Bachelor of Geographical Sciences (3 years)</li>
                    </ol>
                    <h6>The following programmes are in their final approval stages and will soon be launched;</h6>
                    <ol>
                        <li>PhD in Disaster Risk Management</li>
                    </ol>
                    <h6>Our strategic actions entail;</h6>
                    <ol type='a'>
                        <li>Mobilization of funding for research through competitive grants for students, research fellows and faculty</li>
                        <li>Wider dissemination of research results and policy engagements</li>
                        <li>Increased participation of departmental staff in events, conferences and workshops both national and international through their services to the professional and society</li>
                        <li>Functional research labs and new interdisciplinary research programs</li>
                        <li>Increased literacy and competency of students and department in computer and IT for E-learning, communication and research</li>
                        <li>Enhanced use of geo-information technology in spatial analysis and research by students and lecturers Resources</li>
                    </ol>
                    <h6>We boast of the following state of art equipment and resources;</h6>
                    <ol>
                        <li>Eighteen full time Academic Staff, all qualified at PhD level.</li>
                        <li>GEONETCAST Satellite Receiver at CIT building</li>
                        <li>Equipped GIS laboratory with field equipment including GPS, Remote sensing, soil analysis and water testing</li>
                        <li>A functioning synoptic weather Station</li>
                        <li>An Urban Action Research Lab and the Habitat UNI on Climate Change Hub</li>
                        <li>Climate and Water Resources Center</li>
                        <li>Mountain Resources and Disaster Management Center</li>
                        <li>A well-stocked book bank</li>
                    </ol>
                    <h6>Organization of the Department</h6>
                    <p>The Department is organized through Coordinators who assist the Head of Department in the day to day running of the affairs. <br />These include;</p>
                    <ol>
                        <li>Coordinator of Examinations</li>
                        <li>Coordinator of Graduate programmes</li>
                        <li>Coordinator of Undergraduate programmes (Bachelor Geographical Sciences)</li>
                        <li>Coordinator of Undergraduate Programmes (Bachelor of Science in Meteorology)</li>
                        <li>Secretary to the Departmental Academic Board</li>
                        <li>Secretary to the Departmental Appointments and Promotions Committee</li>
                        <li>Finance and Welfare Officer</li>
                        <li>Media Communications Officer</li>
                    </ol>
                    <h6>For more Information, please kindly reach us at;</h6>
                    <address>
                        Makerere University <br />
                        Department of Geography, Geo-informatics & Climatic Sciences <br />
                        P. O. Box 7062, Kampala (Uganda) <br />
                        Tel: +256 414 531 261 <br />
                        Fax: +256 414 531 641 <br />
                        Email: geog@caes.mak.ac.ug or ggcsmakerere@gmail.com
                    </address>
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}

export default WelcomeMessage