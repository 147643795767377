import React from 'react'
import { Link } from 'react-router-dom'
import { domain } from './Domain'
import "../css/News.css"

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const NewsSkeleton = () => {
    return(
        <div className='news_skeleton'>
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div>
                    <Skeleton height={300} width={"100%"} count={1} />
                    <div>
                        <Skeleton height={20} width={"30%"} count={1} />
                        <Skeleton height={10} count={1} /><Skeleton height={10} width={"20%"} count={1} />
                        <Skeleton height={10} width={"10%"} count={1} /><Skeleton height={10} width={"30%"} count={1} />
                        <Skeleton height={10} count={4} />
                        
                        <Skeleton height={10} width={"25%"} count={1} />
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    )
}
const News = ({data}) => {

  const truncateString = (str)=> {
    return str.length > 200 ? str.substring(0, 199) + "..." : str;
  }

  return (
    <div className='news'>
        <img src={domain+data.cover} />
        <div className='bg-light'>
            <span>august, 3, 2023</span>
            <h3>{data.title}</h3>
            <span>by <b>{data.by}</b></span>
            <p className='text-muted lead fs-6' dangerouslySetInnerHTML={{ __html: truncateString(data.details) }}></p>
            <hr />
            <div className='bg-transparent'>
                <Link to={`/news/${data.slug}`} className='btn ps-4 pe-4 btn-danger rounded-pill border-0'>Continue Reading</Link>
            </div>
        </div>
    </div>
  )
}

export default News