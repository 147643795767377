import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { Link, NavLink } from 'react-router-dom'
import "../css/Entry.css"
import DrMukwaya from "../assets/Dr_Mukwaya.jpg"
import News from './News'
import useFetch from './UseFetch'
import { domain } from './Domain'
import Loader from './Loader'
import { NewsSkeleton } from './News'

const Entry = () => {
  const {data, loading, error} = useFetch(`${domain}/news?home=true`);
  const [ spinner, setSpinner ] = useState(true);
  const heading = "Department of Geography, Geo-informatics and Climatic Sciences"
  const title = "A Venue where discovery meets innovation, and knowledge converges with the world. Embark on a journey of exploration, research, and learning as we navigate the fascinating realms of geography, geo-informatics, and climatic sciences. Join us in unraveling the mysteries of our planet, understanding our environment, and shaping a sustainable future. We're excited to have you as part of our vibrant academic community."
  const cls = 'header-home'
  const items = Array(3).fill(null);

  useEffect(()=>{
    setTimeout(() => setSpinner(false), 500);
  }, [])
  return (
    <div>
        {spinner ? <Loader /> : <>
        <Header heading={heading} cls={cls} title={title}/>
        <div className='d-block entry d-md-flex message justify-content-center align-items-center'>
            <div className='flex-grow-1 p-3 div1'>
              <h2>Welcome</h2>
              <p>Hello and welcome to the Department of Geography, Geoinformatics and Climatic Sciences, one of the Departments within the School of Forestry, Environmental and Geographical Sciences, Makerere University.</p>
              <p>We offer crosscutting academic programmes and research, combining the aspects of Human, Physical and Environmental Geography. Our research foci spans climate science, climate change and earth observation; Coupled human-environment systems, demography and development; disaster risk management and reduction and Urban and regional systems, transformations and sustainability. </p>
              <p>We are a highly networked Department working with several local, regional and international collaborators to address some of the contemporary issues of our time. We are open to more partnerships and collaborations.</p>
              <p>For more inforamtion about the department, please navigate our website and incase you fail to find what you are looking out for, kindly contact us thhrough our <Link to={'/contact'}>contact page</Link></p>
              <Link to={'/welcome-message'} className='btn btn-danger rounded-pill ps-4 pe-4'>Read More --</Link>
            </div>
            <div className='flex-grow-1 p-3'>
              <h2>Chair - Dr. Paul Isolo Mukwaya</h2>
              <img src={DrMukwaya}/>
            </div>
        </div>
        <div className='study d-flex gap-4 bg-light'>
          <h2>Study with us</h2>
          <Link to={'/undergraduate-study'}>
            <img src='images/undergraduate.jpeg' />
            <p>Undergraduate study</p>
          </Link>
          <Link>
            <img src='images/makerere.jpeg' />
            <p>Undergraduate Open Days</p>
          </Link>
          <Link to={'/graduate-study'}>
            <img src='images/masters.jpeg' />
            <p>Masters study</p>
          </Link>
          <Link to={'/reasons-why-study-with-us'}>
            <img src='images/reasons.jpeg' />
            <p>Reasons Why Study With Us</p>
          </Link>
        </div>
        <div className='newsDiv'>
          <h1 className='mb-3 ps-3'>Latest News & Events</h1>
          {loading ?  items.map((item, index) => (<NewsSkeleton key={index}/>)) :
          <>
            {data && 
              (
                data.length > 0 && data.map(data => {
                  return(
                    <div className='flex'>
                      <News data={data} key={data.id}/>
                    </div>
                  )
                })
              )
            }
          </>
          }
        </div>
        <Footer />
      </>}
    </div>
  )
}

export default Entry