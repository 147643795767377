import React, {useEffect} from 'react'
import "../css/Footer.css"
import { Link } from 'react-router-dom'
import TwitterWidget from './Twitter';
const twitterEmbedCode = `
<a class="twitter-timeline" href="https://twitter.com/Makerere?ref_src=twsrc%5Etfw">Tweets by Makerere</a>
`;


const Footer = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.charset = 'utf-8';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    return (
        <>
            <div className='gap-3 footer'>
                <div className='div'>
                    <h3>Who we are</h3>
                    <p>The history of the department dates back to the early 1940s as one of the first departments in the university. Before it became the Department of Geography, Geo-informatics and Climatic Sciences, the Department resided in the then Faculty of Arts. It was until 2011 that the department adopted the new name, under the College of Agriculture and Environmental Sciences [CAES].</p>
                </div>
                <div className='div'>
                    <h3>Contact Info</h3>
                    <p>Physical Address: CHUSS Building, South Wing 1st Floor Rm 20 Makerere University <br />P.O. Box 7062, Kampala (Uganda)</p>
                    <p><span className='fw-bold'>Phone:</span> +256 414 531 261</p>
                    <p><span className='fw-bold'>Email:</span> geography@mak.ac.ug</p>
                </div>
                <div className='div'>
                    <h3>Quick Links</h3>
                    <Link to={'https://www.mak.ac.ug/admissions'}>Admissions</Link>
                    <Link to={'https://www.mak.ac.ug/application-procedures/online-application-instructions'}>How to apply</Link>
                    <Link to={'https://ual.mak.ac.ug/'}>UAL</Link>
                    <Link to={'https://www.mak.ac.ug/'}>Makerere University</Link>
                </div>
                <div className='div'>
                    <h3>Follow Us </h3>
                    <div className='w-100' style={{height:'250px', overflow:'auto'}} dangerouslySetInnerHTML={{ __html: twitterEmbedCode }}/>
                </div>
            </div>
            <div className='copyright d-flex justify-content-end align-items-center'>
                <p className='pe-4 pt-2'>Powered by <span className='fw-bold text-success'>Cynaut-Technologies</span></p>
            </div>
        </>
    )
}

export default Footer