import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { Nav } from './Header'
import { domain } from './Domain'
import useFetch from './UseFetch'
import { json, useParams } from 'react-router-dom'
import "../css/Units.css"
import unitsImage from "../assets/units.avif"
import Loader from './Loader'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const items = Array(1).fill(null);
const UnitsSkeleton = () => {
    return(
        <div className='mt-4 mb-4 div'>
            <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
                <Skeleton width={100} height={20} count={1} />
                <div className="Info">
                    <Skeleton width={200} height={10} count={1} />
                    <Skeleton width={400} height={10} count={2} />
                    <Skeleton height={10} count={4} />
                    <Skeleton width={400} height={200} count={1} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

const Units = () => {
    const { name } = useParams()
    const heading = name
    const title = "Our Units and Centers"
    const {data, loading, error} = useFetch(`${domain}/units?q=${name}`);
    const cls = "header-other"

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={heading.replaceAll("-"," ")} title={title} img={unitsImage}/>
            <div className='units-div'>
                {loading ? <div className='inner'>{items.map((item, index) => (<UnitsSkeleton key={index}/>))}</div> : <>
                    {data && data.details !== "" ?
                    <div className='inner'>
                        <h1 className='pt-4 d-none'>{name.replaceAll("-"," ")}</h1>
                        <p dangerouslySetInnerHTML={{__html: data.details}}></p>
                    </div>
                    :
                    <div className='inner position-relative bg-light' style={{height:'40vh'}}>
                        <div className='text-center position-absolute top-50 start-50' style={{transform:"translate(-50%, -50%)"}}>
                            <i class="fa-solid fs-2 fa-bug text-danger"></i>
                            <h1 className='text-danger fs-2 fw-bolder'>No Results Found</h1>
                            <p>We could not find what you requested</p>
                            <p>Please Try Again!</p>
                        </div>
                    </div>
                }</>}
            </div>
            <Footer />
            </>}
        </>
  )
}

export default Units