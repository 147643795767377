import React, {useState, useEffect} from 'react'
import {useLocation, useParams} from 'react-router-dom';
import Header from './Header'
import Footer from './Footer'
import former from "../assets/personDetails.jpeg"
import { domain } from './Domain';
import "../css/PeopleDetails.css"
import Loader from './Loader';
import useFetch from "./UseFetch";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const DetailsSkeleton = () => {
    return(
        <div className='container-lg people-details bg-light pt-3'>
            <SkeletonTheme baseColor="#ddd3" highlightColor="#5555">
                <div className='row'>
                    <div className='col-3'><Skeleton height={300} width={"100%"} count={1} /></div>
                    <div className="Info col-9">
                        <Skeleton height={20} width={"30%"} count={1} />
                        <Skeleton height={10} width={"100%"} count={3} />
                        <Skeleton height={20} width={"50%"} count={1} />
                        <Skeleton height={10} width={"100%"} count={5} />
                    </div>
                </div>
                <Skeleton height={20} width={"20%"} count={1} />
                <Skeleton height={10} count={4} />
            </SkeletonTheme>
        </div>
    )
}
const PeopleDetails = () => {
    const {name} = useParams()
    const {data, loading, error} = useFetch(`${domain}/people/${name}`);
    const location = useLocation();
    const [loaded, setLoaded] = useState(false);
    //const title = location.state.position ? location.state.position : "no position specified";
    const cls = 'header-other'
    //const names = location.state.name
    //location.state.brief_info


    const [ spinner, setSpinner ] = useState(true);
    const [imageDimensions, setImageDimensions] = useState({
        width: null,
        height: null
    });
    
    const handleImageLoaded = (event) => {
        const { naturalWidth, naturalHeight } = event.target;
        setImageDimensions({ width: naturalWidth, height: naturalHeight });
        setLoaded(true);
    };

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <div>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={name.replaceAll("-"," ")} title={data && data.position ? data.position: "no position specified"} img={former}/>
            <p className='d-none'>{JSON.stringify(location.state)}</p>
            {loading && <DetailsSkeleton />}
            {data && 
                <div className='container-lg people-details bg-light pt-3'>
                    <div className='staff gap-4'>{/*d-block d-md-flex */}
                        {!loaded && <Skeleton width={300} height={350} count={1} />}
                        <img src={domain+data.image} alt={data.name} onLoad={handleImageLoaded} style={{ display: loaded ? 'block' : 'none' }} class="float-left"/>
                        <p className="brief-info" dangerouslySetInnerHTML={{__html: data.brief_info}}></p>
                    </div>
                    <div className='p-2 w-100' dangerouslySetInnerHTML={{__html: data.bio_data}}></div>
                </div>
            }
            <Footer />
            </>}
        </div>
    )
}

export default PeopleDetails