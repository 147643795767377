import React, {useEffect, useState} from 'react'
import Header from './Header'
import Footer from './Footer'
import { Nav } from './Header'
import { useParams } from 'react-router-dom'
import "../css/Projects.css"
import projectImage from "../assets/projects.avif"
import useFetch from "./UseFetch";
import { domain } from "./Domain";
import Loader from './Loader'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const items = Array(1).fill(null);
const ProjectSkeleton = () => {
    return(
        <div className='mt-4 mb-4 div'>
            <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
                <Skeleton width={100} height={20} count={1} />
                <div className="Info">
                    <Skeleton width={200} height={10} count={1} />
                    <Skeleton width={400} height={10} count={2} />
                    <Skeleton height={10} count={4} />
                    <Skeleton width={400} height={300} count={1} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

const Gallery = ({data}) => {
    const [loaded, setLoaded] = useState(false);
    const handleImageLoad = () => {
        setLoaded(true);
    };
    return(
        <div>
            {!loaded && <Skeleton height={250} count={1} />}
            <img 
                className='w-100' 
                src={domain+data.image} 
                alt='...'
                onLoad={handleImageLoad}
                style={{ display: loaded ? 'block' : 'none' }} />
        </div>
    )
}


const Project = ({data}) => {
    const items = Array(4).fill(null);
    return(
        <div className='details-project'>
            {data.project.details &&
            <div>
                <p dangerouslySetInnerHTML={{__html: data.project.details}}></p>
            </div>
            }
            {data.team && data.team.length > 0 &&
            <div>
                <h6>Project Team Members</h6>
                <div className='members d-flex flex-wrap align-items-center gap-2'>
                    {data.team && 
                        data.team.map(data => {
                            return(
                                <figure className='flex-grow-1'>
                                    <img src={domain+data.image} />
                                    <figcaption>{data.name}</figcaption>
                                </figure>
                            )
                        })
                    }
                </div>
            </div>
            }
            {data.qualifications && data.qualifications.length > 0 &&
            <div>
                <h6>Accomplishments so far</h6>
                <table>
                {data.all_qualifications && data.all_qualifications.map(qual => (
                    <div key={qual.id}>
                        <h6 className='pb-3 text-danger'>{data.qualifications.filter(item => item.category === qual.id).length} students trained at {qual.name}</h6>
                        <thead>
                            <tr>
                                <th>Name/Country</th>
                                <th>Thesis Title</th>
                                <th>Qualification/ Awarding Institution</th>
                                <th>Employment/Career prospects</th>
                            </tr>
                        </thead>
                        {data.qualifications.filter(item => item.category === qual.id).map(datas => (
                            <tr>
                                <td>
                                    {datas.image !== '/media/qualifications/default.png' && <img src={domain+datas.image} />}
                                    <figcaption>{datas.name}</figcaption>
                                </td>
                                <td>{datas.title}</td>
                                <td>{datas.qualification}</td>
                                <td>{datas.career_prospects}</td>
                            </tr>
                        ))}
                    </div>
                ))}
                </table>
            </div>
            }
            {data.project.components &&
            <div>
                <p dangerouslySetInnerHTML={{__html: data.project.components}}></p>
            </div>
            }
            <div className='d-none'>
                <p dangerouslySetInnerHTML={{__html: data.project.accomplishments}}></p>
            </div>
            {data.publications && data.publications.length > 0 &&
            <div>
                <h6>Publications</h6>
                {data.publications && data.publications.map(data => {
                    return(
                        <div className='publications d-flex gap-4 align-items-center border-bottom'>
                            <p className='fw-bold'>{data.year}</p>
                            <p dangerouslySetInnerHTML={{__html: data.details}}></p>
                        </div>
                    )
                })}
            </div>
            }
            {data.project.end_content &&
            <div>
                <p dangerouslySetInnerHTML={{__html: data.project.end_content}}></p>
            </div>
            }
            {data.project.partners &&
            <div>
                <p dangerouslySetInnerHTML={{__html: data.project.partners}}></p>
            </div>
            }
            {data.gallery && data.gallery.length > 0 &&
            <div>
                <h6>Gallery</h6>
                <div className='flexed d-block d-md-flex flex-wrap gap-3'>
                {data.gallery.map(data => {
                    return(
                        <Gallery data = {data}/>
                    )
                })}
                </div>
            </div>
            }
            <div className='d-none'>
                <p dangerouslySetInnerHTML={{__html: data.project.others}}></p>
            </div>
        </div>
    )
}

const Projects = () => {
    const { name } = useParams()
    const {data, loading, error} = useFetch(`${domain}/projects?name=${name}`);
    const heading = "Research Themes & Projects"
    const title = "Thank you for visiting our projects, we look forward to working with you in one way or the other"
    const cls = 'header-other'

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={heading} title={title} img={projectImage}/>
            <div className='Project'>
                <div className='inner'>
                    <h1 className='pt-4'>{name.replaceAll("-"," ")}</h1>
                    {loading ?  items.map((item, index) => (<ProjectSkeleton key={index}/>)):
                        <>
                            {data && data.project ? <Project data = {data}/> :
                            <div className='text-center w-100'>
                                <div className="p-4 text-center">
                                    <i class="fa-solid fs-2 fa-bug text-danger"></i>
                                    <h1 className='text-danger fs-2 fw-bolder'>No Results Found</h1>
                                    <p>We could not find what you requested</p>
                                    <p>Please Try Again!</p>
                                </div>
                            </div>
                            }
                        </>
                    }
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}
//<p className='pt-4 pb-4 fw-bold'>Loading Data, please wait ...</p>
export default Projects