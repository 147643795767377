import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { Nav } from './Header'
import { useParams } from 'react-router-dom'
import "../css/Uga.css"
import ugaImage from "../assets/uga.jpeg"
import useFetch from "./UseFetch";
import { domain } from "./Domain";
import Loader from './Loader'

const Uga = () => {
    const {data, loading, error} = useFetch(`${domain}/uga`);
    const heading = "Uganda Geography Association"
    const title = "Associations"

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls='header-other' vid={false} heading={heading} title={title} img={ugaImage}/>
            <div className='uga-div'>
                <div className='inner'>
                    <h6 className='fw-bold'>Uganda Geography Association</h6>
                    <p>The outgoing General Secretary, President and chairman Electoral commission during the meeting.</p>
                    <p>The outgoing executive leaders thanked the association members for being active and urged them to continue engaging in association activities with the new cabinet that would be elected. Specifically, the outgoing President and General Secretary to urged the new cabinet members to Interest fresh graduates to join the association, strengthen linkages with the new Universities, Strengthen publications e.g news letters, Field work, Mobilise more members especially teachers’ groups, Geography book in honor of late Bagoora, Reviving the UGA Journal, Recognising senior members of UGA and Organising annual tutoring courses.</p>
                    <div>
                        <img src='' />
                        <img src='' />
                    </div>
                    <p>The elections were conducted successfully under the guidance of Dr. Abel Nzabona as Chairman Electoral commission. As shown in Table 1, the newly elected executive members were sworn under the leadership of the new President, Prof. Frank Mugagga. The out going executive members handed over instruments of power under the leadership of the Prof. Fredrick Tumwine Ruguma to the newly elected executive members. The president-elect, Prof Frank Mugagga presented his acceptance speech where he thanked members for nominating him (unopposed) and expounded to use his position to better the visibility of the association and deliver according to the objectives of the association</p>
                    {loading ? <p className='pt-4 pb-4 fw-bold'>Loading Data, please wait ...</p> :
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>UGA Positions</th>
                                <th>Outgoing Executive members (2014-2019)</th>
                                <th>Incoming Executive members (2019-2024)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.map(data => {
                                    return(
                                        <tr>
                                            <td>{data.id}</td>
                                            <td>{data.position}</td>
                                            <td>{data.outgoing}</td>
                                            <td>{data.incoming}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    }
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}

export default Uga