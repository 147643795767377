import React, { useEffect, useState } from "react";
import Header from './Header'
import Footer from './Footer'
import { Nav } from './Header'
import { useParams } from 'react-router-dom'
import useFetcht from "./UseFetch";
import "../css/People.css"
import logo from "../assets/noImage.png"
import former from "../assets/former.avif"
import useFetch from "./UseFetch";
import { domain } from "./Domain";
import { Link, useNavigate } from "react-router-dom";
import Loader from "./Loader";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const truncateString = (str)=> {
    return str.length > 200 ? str.substring(0, 100) + "..." : str;
}

const Person = ({data}) => {
    const navigate = useNavigate()
    const handleChange = (e,data) => {
        e.preventDefault()
        navigate(`/people/details/${data.slug}`, { state: data })
    }
    const [loaded, setLoaded] = useState(false);

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return(
        <div className='mt-4 mb-4 div'>
            {!loaded && <Skeleton height={250} count={1} />}
            <img 
                src={data.image === '/media/people/default.png' ? logo : domain+data.image} 
                alt='person' 
                onLoad={handleImageLoad}
                style={{ display: loaded ? 'block' : 'none' }}/>
            <div className="Info">
                <p className="name fw-bolder">{data.name}</p>
                <p className="d-none brief-info" dangerouslySetInnerHTML={{__html: truncateString(data.brief_info)}}></p>
                <p className="d-none" dangerouslySetInnerHTML={{__html: data.bio_data}}></p>
                <p className="d-none">{data.position}</p>
                <Link className="notDisabled" to={`/people/details/${data.slug}`} onClick={(e)=> handleChange(e,data)}>{data.name} Details ...</Link>
            </div>
        </div>
    )
}

const PersonSkeleton = () => {
    return(
        <div className='mt-4 mb-4 div'>
            <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
                <Skeleton height={200} count={1} />
                <div className="Info">
                    <Skeleton height={10} count={3} />
                </div>
            </SkeletonTheme>
        </div>
    )
}
const People = () => {
    const { name } = useParams()
    const {data, loading, error} = useFetch(`${domain}/people?category=${name}`);
    const title = ""
    const cls = 'header-other'

    // Separate data into names with and without 'prof'
    const dataWithAssoc = data?.filter(item => item.position ==='Associate Professor') || [];
    const dataWithProf = data?.filter(item => item.position ==='Professor') || [];
    const dataWithSenior = data?.filter(item => item.position ==='Senior Lecturer') || [];
    const dataWithout = data?.filter(item => item.position !== 'Professor' && item.position !== 'Associate Professor' &&  item.position !=='Senior Lecturer') || [];

    // Combine the two arrays
    const combinedData = [...dataWithAssoc, ...dataWithProf, ...dataWithSenior, ...dataWithout];

    const [ spinner, setSpinner ] = useState(true);
    const items = Array(4).fill(null);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])
    //<p className='pt-4 pb-4 fw-bold'>Loading {name.replaceAll("-"," ")} Data, please wait ...</p>
    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={name !== 'former-leaders' ? name.replaceAll("-"," ") : 'former heads of department'} title={title} img={former}/> 
            <div className='People'>
                <div className='inner d-flex flex-wrap'>
                    <h1 className='pt-4 d-none'>{name.replaceAll("-"," ")}</h1>
                    {loading ?  items.map((item, index) => (<PersonSkeleton key={index}/>)) :
                    <>
                    {name === 'former-leaders' ? data && data.length > 0 ?
                        data.map((item, index) => (
                            <Person key={index} data={item} />
                        ))
                        :
                        <div className='text-center w-100'>
                            <div className="p-4 text-center">
                                <i class="fa-solid fs-2 fa-bug text-danger"></i>
                                <h1 className='text-danger fs-2 fw-bolder'>No Results Found</h1>
                                <p>We could not find what you requested</p>
                                <p>Please Try Again!</p>
                            </div>
                        </div>
                    :
                    combinedData && combinedData.length > 0 ?
                        combinedData.map((item, index) => (
                            <Person key={index} data={item} />
                        ))
                        :
                        <div className='text-center w-100'>
                            <div className="p-4 text-center">
                                <i class="fa-solid fs-2 fa-bug text-danger"></i>
                                <h1 className='text-danger fs-2 fw-bolder'>No Results Found</h1>
                                <p>We could not find what you requested</p>
                                <p>Please Try Again!</p>
                            </div>
                        </div>}
                    </>}
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}

export default People