import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import '../css/Study.css'
import ugaImage from "../assets/uga.jpeg"
import useFetch from "./UseFetch";
import { domain } from "./Domain";
import Loader from './Loader'

const Reasons = () => {
    const heading = "Reasons Why Study With Us"
    const title = "Makerere University"

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])
    return (
        <div>
            {spinner ? <Loader /> : <>
            <Header cls='header-other' vid={false} heading={heading} title={title} img={ugaImage}/>
            <div className='study'>
                <div className='inner'>
                    <h6>Below are some of the reasons to as why you should Join Us</h6>
                    <ol>
                        <li>Academic Reputation</li>
                        <li>Programs and Majors</li>
                        <li>Strategic Location</li>
                        <li>Cost and Financial Aid</li>
                        <li>Availability of Campus Facilities</li>
                        <li>Class Sizes and Teaching Methods</li>
                        <li>Faculty Expertise</li>
                        <li>Diversity and Inclusivity</li>
                        <li>Accreditation</li>
                        <li>Alumni Success</li>
                        <li>Networking</li>
                        <li>Research Opportunitie</li>
                    </ol>
                    <h6>For more Information, please kindly reach us at;</h6>
                    <address>
                        Makerere University <br />
                        Department of Geography, Geo-informatics & Climatic Sciences <br />
                        P. O. Box 7062, Kampala (Uganda) <br />
                        Tel: +256 414 531 261 <br />
                        Fax: +256 414 531 641 <br />
                        Email: geog@caes.mak.ac.ug or ggcsmakerere@gmail.com
                    </address>
                </div>
            </div>
            <Footer />
            </>}
        </div>
    )
}

export default Reasons