import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Entry from './components/Entry';
import WelcomeMessage from './components/WelcomeMessage';
import People from './components/People';
import Projects from './components/Projects';
import Uga from './components/Uga';
import Contact from './components/Contact';
import Units from './components/Units';
import NewsDetails from './components/NewsDetails';
import PeopleDetails from './components/PeopleDetails';
import Structure from './components/Structure';
import Undergraduate from './components/Undergraduate';
import GraduateStudy from './components/GraduateStudy';
import Reasons from './components/Reasons';
import { ActivePageProvider } from './components/ActivePageContext';
import 'animate.css';

function App() {
  return (
    <div className="App">
      <ActivePageProvider>
        <Routes>
          <Route path='/' element={<Entry />}/>
          <Route path='/undergraduate-study' element={<Undergraduate />}/>
          <Route path='/graduate-study' element={<GraduateStudy />}/>
          <Route path='/reasons-why-study-with-us' element={<Reasons />}/>
          <Route path='/welcome-message' element={<WelcomeMessage />}/>
          <Route path='/people/:name' element={<People />}/>
          <Route path='/people/details/:name' element={<PeopleDetails />}/>
          <Route path='/projects/:name' element={<Projects />}/>
          <Route path='/units/:name' element={<Units />}/>
          <Route path='/news/:name' element={<NewsDetails />}/>
          <Route path='/associations/:name' element={<Uga />}/>
          <Route path='/contact' element={<Contact />}/>
          <Route path='/structure' element={<Structure />}/>
        </Routes>
      </ActivePageProvider>
    </div>
  );
}

export default App;
