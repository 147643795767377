import React, {useState, useEffect} from 'react'
import Header from './Header'
import Footer from './Footer'
import { domain } from './Domain'
import useFetch from './UseFetch'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import "../css/NewsDetails.css"
import unitsImage from "../assets/units.avif"
import Loader from './Loader'
import { DNA } from 'react-loader-spinner'

const Loaders = () => {
    return(
        <div className='d-flex align-items-center gap-4'>
            <DNA
                visible={true}
                height="80"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
            />
            <h5>Loading</h5>
        </div>
    )
}


const NewsDetails = () => {
    const { name } = useParams()
    const {data, loading, error} = useFetch(`${domain}/news/${name}`);
    const title = "Thank you for visiting our projects, we look forward to working with you in one way or the other"
    const cls = 'header-other'

    const [ spinner, setSpinner ] = useState(true);

    useEffect(()=>{
        setTimeout(() => setSpinner(false), 500);
    }, [])

    return (
        <>
            {spinner ? <Loader /> : <>
            <Header cls={cls} vid={false} heading={data && "Posted by Mr. "+data.news.by} title={data && "on "+ new Date(data.news.created_at).toLocaleString()} img={unitsImage}/>
            <div className='NewsDetails'>
                <div className='inners row'>
                    <div className='col-lg-8'>
                        {error && <p>{error}</p>}
                        {loading && <Loaders />}
                        {data && 
                            <div>
                                <h2 className='pt-4 fs-6 pb-2'><span className='fw-bolder'>{data.news.title}</span></h2>
                                <img className='img-fluid pb-2' src={domain+data.news.cover}/>
                                <p className='fw-light' dangerouslySetInnerHTML={{ __html: data.news.details }}></p>
                            </div>
                        }
                    </div>
                    <div className='col-lg-4'>
                        <div className='position-sticky top-0'>
                            <h1 className='fs-3 text-secondary pt-4 fw-bold' style={{fontFamily: "Playfair"}}><span className='fw-bolder'>News Feed</span></h1>
                            {error && <p>{error}</p>}
                            {loading && <Loaders />}
                            {data && data.other_news.slice(0, 10).map(data =>{
                                return(
                                    <>
                                        <Link to={`/news/${data.slug}`} className='d-block text-success border-bottom pt-2 pb-2'>{data.title} <br /><small className='text-muted'>posted on - {new Date(data.created_at).toLocaleString()}</small></Link>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            </>}
        </>
    )
}

export default NewsDetails